$black900: #212121;
$black800: #424242;
$black600: #616161;
$black500: #9e9e9e;
$black400: #bdbdbd;
$black300: #e0e0e0;
$black200: #eeeeee;
$black100: #f4f4f4;
$white: #ffffff;
$purple: #5c38a7;
.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: $purple;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: $purple;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: $purple;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: $purple;
}

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: $purple;
}

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: $purple;
}

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: $purple;
}

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: $purple;
}

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: $purple;
}

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: $purple;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: $purple;
  min-width: 280px;
  max-width: 357px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba($black900, 0.3);
  transition: opacity 0.1s ease-out;
  background-color: $purple;
  color: $white;
}
.introjs-tooltipReferenceLayer * {
  font-family: "Poppins" !important;
}
.introjs-tooltiptext {
  padding-left: 16px;
  padding-right: 40px;
  padding-top: 0px;
  padding-bottom: 10px;
  color: $white;
}
.introjs-tooltip-title {
  display: none;
}
.introjs-tooltip-header {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  min-height: min-content;
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.introjs-tooltipbuttons {
  border-top: none;
  padding: 0px 16px 16px 16px;
  text-align: right;
  white-space: nowrap;
  &:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.introjs-button {
  @apply px-3 py-1;
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  text-decoration: none;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  color: $white;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $white;
  &:hover {
    outline: none;
    border-color: $white;
    color: $white;
    background-color: transparent;
    font-style: normal !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
    font-style: normal !important;
  }
  &:active {
    outline: none;
    border-color: $white;
    color: $white;
    background-color: transparent;
    font-style: normal !important;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

.introjs-skipbutton {
  position: absolute;
  top: 6px;
  right: 0px;
  border: 0;
  background: transparent;
  color: white;
  font-size: 20px;
  line-height: 13px;
  margin: 0;
  padding: 10px;
  &:hover {
    color: white;
  }
}
.introjs-nextbutton {
  float: right;
  border: none;
  &:hover,
  &:active,
  &:focus {
    border: none;
  }
}
.introjs-donebutton {
  border: 1px solid white;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid white;
  }
}
.introjs-prevbutton {
  float: left;
  display: none;
}

.introjs-bullets {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  padding: 0px 20px;
  pointer-events: none;
  ul {
    box-sizing: content-box;
    clear: both;
    margin: 12px auto;
    padding: 0;
    display: inline-block;
    li {
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px;
      a {
        transition: width 0.1s ease-in;
        box-sizing: content-box;
        display: block;
        width: 7px;
        height: 7px;
        background: #aa93db;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer;
        &:hover,
        &:focus {
          width: 7px;
          background: #aa93db;
          text-decoration: none;
          outline: none;
        }
      }
      a.active {
        width: 7px;
        background: white;
      }
    }
  }
}
