.alert-card {
  @apply absolute w-[315px] rounded-lg bg-red-100 px-8 py-5;
  /* CSK - #E18B82/100 */
}
.card-title {
  @apply text-base font-semibold leading-[19px] text-red-800;
  /* CSK - #E18B82/800 */
}
.card-text {
  @apply pt-4 text-base font-normal leading-[19px] text-red-800;
  /* CSK - #E18B82/800 */
}
a.card-dismiss {
  @apply pt-4 text-base font-semibold leading-[19px] text-red-800 hover:text-red-800;
  /* CSK - #E18B82/800 */
}
.alert-card-success {
  @apply bg-green-50;
}
.alert-card-success .card-title,
.alert-card-success .card-text,
.alert-card-success a.card-dismiss,
.alert-card-success a.card-dismiss:hover {
  @apply text-green-700;
}
.alert-card-info {
  @apply bg-purple-50;
}
.alert-card-info .card-title,
.alert-card-info .card-text,
.alert-card-info a.card-dismiss,
.alert-card-info a.card-dismiss:hover {
  @apply text-purple-500;
}
.card-body div#expand-container {
  @apply overflow-hidden;
}
.card-body div#expand-target {
  @apply mt-[-100%] h-0 -translate-y-2/4 translate-x-0  transition-all;
  animation-duration: 0.5s;
}
.card-body div#expand-target.expanded {
  @apply mt-0 h-full translate-x-0 translate-y-0;
}
