.gi {
  width: 15px;
  height: 15px;
  font-size: 15px;
}
.gi-sm {
  width: 15px;
  height: 15px;
  font-size: 15px;
}
.gi-md {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.gi-lg {
  width: 24px;
  height: 24px;
  font-size: 24px;
}