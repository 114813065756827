/* enable absolute positioning */
.inner-addon {
  @apply relative flex w-full items-center;
}

/* style icon */
.left-glyphicon {
  @apply pointer-events-none absolute p-5 text-base;
}
.right-glyphicon {
  @apply pointer-events-none absolute right-0 top-0 p-5 text-base;
}
.show-button-wrapper {
  @apply absolute right-2 h-full grid place-items-center;
}
.giboo-input {
  @apply flex h-12 flex-col items-start rounded border border-solid border-gray-400 bg-transparent px-6 py-0 text-sm font-normal not-italic leading-5 text-gray-600 focus:border-gray-600 focus:bg-transparent focus:text-gray-700 focus:shadow-none disabled:border-gray-500 disabled:bg-gray-100 disabled:text-gray-500;
}
textarea.giboo-input {
  @apply p-5;
}
.giboo-input::placeholder {
  @apply border-gray-400 text-gray-500;
}
.giboo-input::-webkit-input-placeholder {
  @apply border-gray-400 text-gray-500;
}
.giboo-input:-moz-placeholder,
.giboo-input::-moz-placeholder,
.giboo-input:-ms-input-placeholder,
.giboo-input::-ms-input-placeholder {
  @apply border-gray-400 text-gray-500;
}
input.form-invalid,
textarea.form-invalid {
  @apply border-purple-500 bg-purple-50 text-gray-700;
}
.giboo-input-purple {
  @apply border-purple-600 focus:border-purple-500;
}
.giboo-input-red {
  @apply border-red-600 focus:border-red-500;
}
.giboo-input-yellow {
  @apply border-yellow-600 focus:border-yellow-500;
}
.giboo-input-green {
  @apply border-green-600 focus:border-green-500;
}
.giboo-input-blue {
  @apply border-blue-600 focus:border-blue-500;
}
.giboo-input-with-top-placeholder {
  @apply pt-4;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @apply transition-colors  delay-0 duration-500 ease-in-out;
}
textarea.giboo-input-with-top-placeholder {
  @apply pt-7;
}
i.giboo-input-with-top-placeholder {
  @apply top-2;
}
.giboo-input-inner-label {
  @apply absolute left-5 top-2 text-sm font-normal leading-4;
}
.giboo-input-inner-prefix {
  @apply absolute left-5 top-6 text-sm font-normal leading-4 text-gray-800;
}
.giboo-checkbox.form-check-input[type="checkbox"] {
  @apply m-0 h-5 w-5 cursor-pointer appearance-none rounded-sm border border-solid border-gray-800 bg-white focus:border-gray-900 focus:shadow-none focus:ring-0 hover:border-gray-600 hover:shadow-none;
}
.giboo-checkbox.form-check-input:checked[type="checkbox"] {
  @apply rounded-sm border-gray-800 bg-purple-50;
  background-size: 10px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9.80121%200.128056C10.0356%200.323375%2010.0673%200.671719%209.87194%200.906103L3.96018%208L0.217723%204.25766C0.00197905%204.04192%200.00197913%203.69214%200.217723%203.47641C0.433468%203.26067%200.783259%203.26067%200.999003%203.4764L3.88593%206.36324L9.02314%200.198787C9.21846%20-0.0355967%209.56682%20-0.0672643%209.80121%200.128056Z%22%20fill%3D%22%23222222%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}
.giboo-slider {
  @apply relative px-0 py-1;
}
.giboo-slider-track {
  @apply w-full;
}
.giboo-slider-range {
  @apply absolute top-1 w-full;
}
.giboo-slider-track > * {
  @apply z-10 text-gray-400;
}
.giboo-slider-track .range {
  @apply text-purple-100;
}
.giboo-slider-track > .default {
  @apply h-1 rounded-sm bg-gray-400;
}
.giboo-slider-range > * {
  @apply z-20 text-purple-100;
}
.giboo-slider-range > .default {
  @apply h-1 rounded-sm bg-purple-100;
}
.giboo-slider-thumb,
.giboo-slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.giboo-slider-thumb {
  @apply pointer-events-none absolute -mt-1 h-0 w-full;
  outline: none;
}
.giboo-slider-thumb-zindex-3 {
  @apply z-30;
}
.giboo-slider-thumb-zindex-4 {
  @apply z-40;
}
.giboo-slider-thumb-zindex-5 {
  @apply z-50;
}
.giboo-slider-thumb::-webkit-slider-thumb {
  @apply relative mt-1 h-4 w-4 cursor-pointer rounded-3xl border-2 border-solid border-purple-500 bg-white;
  pointer-events: all;
}
.giboo-slider-thumb::-moz-range-thumb {
  @apply relative mt-1 h-4 w-4 cursor-pointer rounded-3xl border-2 border-solid border-purple-500 bg-white;
  pointer-events: all;
}
.giboo-slider-single-container {
  @apply w-full;
}
.giboo-slider-single {
  @apply h-1 w-full  appearance-none rounded-sm bg-gray-400 outline-none;
}
.giboo-slider-single::-webkit-slider-thumb {
  @apply relative mt-px h-4 w-4 cursor-pointer appearance-none rounded-3xl border-2 border-solid border-purple-500 bg-white;
  -webkit-appearance: none;
  pointer-events: all;
}
.giboo-slider-single::-moz-range-thumb {
  @apply relative mt-px h-4 w-4 cursor-pointer appearance-none rounded-3xl border-2 border-solid border-purple-500 bg-white;
  pointer-events: all;
}
.giboo-checkbox-add[type="checkbox"] {
  @apply appearance-none;
  -webkit-apperance: none;
}
.giboo-checkbox-add[type="checkbox"] + label rect,
.giboo-checkbox-add[type="checkbox"] + label path {
  @apply opacity-0 transition-opacity duration-200;
}
.giboo-checkbox-add[type="checkbox"]:checked + label rect.checked,
.giboo-checkbox-add[type="checkbox"]:checked + label path.checked {
  @apply opacity-100;
}
.giboo-checkbox-add[type="checkbox"]:not([checked]) + label rect.unchecked,
.giboo-checkbox-add[type="checkbox"]:not([checked]) + label path.unchecked {
  @apply opacity-100;
}
.multiple-selector-items {
  @apply whitespace-normal;
}
.multiple-selector-items > div {
  @apply h-full;
}
// .editableinput-container > .editableinput {
//   @apply border-t border-solid border-t-gray-500;

// }
.multiline-truncation {
  @apply overflow-hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}
.giboo-textarea {
  @apply resize-none p-4;
}
.input-underline {
  @apply flex flex-col items-start border-0 border-b border-solid border-b-gray-500 bg-transparent text-base font-normal not-italic leading-5 text-gray-600 outline-none focus:border-gray-600 focus:text-gray-700 focus:shadow-none disabled:border-gray-500 disabled:bg-gray-100 disabled:text-gray-500;
}
.input-range-bar {
  @apply h-0.5 w-5 bg-gray-500;
}
