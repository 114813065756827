.giboo-intro-image-text-box {
  @apply m-4 h-64 w-64  rounded-xl border-none bg-white p-9 shadow-sm;
}
.giboo-intro-image-text-box-label {
  @apply mt-10 text-base font-normal not-italic leading-5 text-purple-900;
}
.dashboard-white-box {
  @apply mb-6 rounded-lg bg-white p-6 shadow-sm;
}
.dashboard-inner-white-box {
  @apply my-3 rounded-lg border-solid border-gray-200 bg-white p-4;
  border-width: 1px;
}
.dashboard-gray-inner-box {
  @apply my-3 rounded-lg bg-neutral-50 p-4;
}
.dashboard-gray-inner-box ul {
  list-style-image: url("star_bullet.svg");
}
.gray-box {
  @apply my-3 rounded-lg p-4;
  background: #f6f7f9;
}
.chart-box-purple {
  @apply overflow-x-hidden bg-purple-50 text-center align-middle text-sm text-gray-700;
}
.chart-box-purple-selected {
  @apply overflow-x-hidden border border-solid border-purple-500 bg-purple-50 text-center align-middle text-sm text-gray-700;
  border-width: 1px;
}
.chart-box-yellow {
  @apply overflow-hidden bg-yellow-50 text-center align-middle text-sm text-gray-700;
}
.chart-box-yellow-selected {
  @apply overflow-hidden  border-solid border-yellow-800 bg-yellow-50 text-center align-middle text-sm text-gray-700;
  border-width: 1px;
}
.chart-box-green {
  @apply overflow-hidden bg-green-50 text-center align-middle text-sm text-gray-700;
}
.chart-box-green-selected {
  @apply overflow-hidden  border-solid border-green-900 bg-green-50 text-center align-middle text-sm text-gray-700;
  border-width: 1px;
}
.chart-box-blue {
  @apply overflow-hidden bg-blue-50 text-center align-middle text-sm text-gray-700;
}
.chart-box-blue-selected {
  @apply overflow-hidden  border-solid border-purple-500 bg-blue-50 text-center align-middle text-sm text-gray-700;
  border-width: 1px;
}
.chart-box-gray {
  @apply overflow-hidden bg-gray-100 text-center align-middle text-sm text-gray-700;
}
.chart-box-gray-selected {
  @apply overflow-hidden  border-solid border-purple-500 bg-gray-100 text-center align-middle text-sm text-gray-700;
  border-width: 1px;
}
