.styles-module_fixed__7ciUi {
  @apply fixed;
}
.styles-module_clickable__Bv9o7 {
  @apply pointer-events-auto;
}
/** Types variant **/
.styles-module_dark__xNqje {
  @apply text-white;
  background: var(--rt-color-dark);
}
