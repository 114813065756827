.onboarding {
  @apply px-0 py-6;
  margin-top: 60px;
  padding-top: 0;
}
.onboarding-header {
  @apply text-2xl text-purple-900;
}
.onboarding-sub-header {
  @apply font-poppins text-sm font-normal text-purple-800;
}
.onboarding-sub-header-gray {
  @apply font-poppins text-sm font-normal text-gray-800;
}
.onboarding-width-xl {
  width: 1280px;
}
.onboarding-width-lg {
  width: 932px;
}
.onboarding-width-lg2 {
  width: 960px;
}
.onboarding-width-md2 {
  width: 672px;
}
.onboarding-width-md {
  width: 600px;
}
.onboarding-width-sm {
  width: 500px;
}
@media (max-width: 767.98px) {
  .onboarding-width-xl {
    width: 767px;
  }
  .onboarding-width-lg2 {
    width: 767px;
  }
  .onboarding-width-lg {
    width: 720px;
  }
  .onboarding-width-md2 {
    width: 564px;
  }
  .onboarding-width-md {
    width: 480px;
  }
  .onboarding-width-sm {
    width: 400px;
  }
}
.onboarding-required {
  @apply mt-4;
}
.onboarding-body {
  @apply mt-8 flex flex-col gap-5;
}
.onboarding-body-row {
  @apply mt-8 flex flex-row gap-5;
}
.onboarding-btns-container {
  @apply mt-8 flex flex-col gap-5;
}
.onboarding-stage {
  @apply cursor-pointer rounded border border-solid border-gray-400 px-4 py-7 text-gray-700 hover:border-purple-600 active:border-purple-600 active:text-purple-500;
}
.onboarding-stage > pre {
  @apply text-gray-400;
}
.onboarding-stage:hover > pre {
  @apply text-gray-800;
}
.onboarding-stage.active {
  @apply border-purple-500 text-purple-500;
}
.onboarding-stage.active > pre {
  @apply text-purple-500;
}
.onboarding-stage:active > pre {
  @apply text-purple-500;
}
