.dropdown {
  @apply w-full;
}
.dropdown-selector {
  @apply w-full rounded;
  min-height: 32px;
}
/*
  DropdownSelector Button
*/
.dropdown-selector-button {
  @apply w-full cursor-pointer  rounded border-solid border-gray-400 bg-inherit px-3 py-2 text-left text-gray-700 disabled:text-gray-500;
  min-height: 32px;
  border-width: 1px;
}
.dropdown-selector-button:hover:not([disabled]),
.dropdown-selector-button:focus:not([disabled]) {
  @apply border-gray-500;
}
.dropdown-selector-button:active:not([disabled]) {
  @apply border-gray-50 bg-gray-50;
}
.dropdown-selector-placeholder {
  @apply font-poppins text-sm font-normal text-gray-500;
}

/*
  DropdownSelector Button Color
*/
.dropdown-selector-button-purple:hover:not([disabled]),
.dropdown-selector-button-purple:focus:not([disabled]) {
  @apply border-purple-500;
}
.dropdown-selector-button-red:hover:not([disabled]),
.dropdown-selector-button-red:focus:not([disabled]) {
  @apply border-red-500;
}
.dropdown-selector-button-yellow:hover:not([disabled]),
.dropdown-selector-button-yellow:focus:not([disabled]) {
  @apply border-yellow-500;
}
.dropdown-selector-button-green:hover:not([disabled]),
.dropdown-selector-button-green:focus:not([disabled]) {
  @apply border-green-500;
}
.dropdown-selector-button-blue:hover:not([disabled]),
.dropdown-selector-button-blue:focus:not([disabled]) {
  @apply border-blue-500;
}
.dropdown-selector-button-gray:hover:not([disabled]),
.dropdown-selector-button-gray:focus:not([disabled]) {
  @apply border-gray-400;
}

/*
  DropdownSelector Item
*/
.dropdown-selector-item-container {
  @apply absolute z-50 w-full bg-white overflow-y-auto overflow-x-hidden border-x border-b border-gray-400 rounded shadow-sm outline-none;
}
.dropdown-selector-item-container.float {
  @apply absolute;
  z-index: 1000;
}
.dropdown-selector-item-container.compact {
  @apply gap-0 rounded border border-solid border-gray-200 bg-[white] shadow-sm;
}
.dropdown-selector-item-container > .dropdown-selector-item {
  @apply first:mt-1 last:mb-1;
}
.dropdown-selector-item {
  @apply max-h-full  cursor-pointer rounded bg-white px-3 text-gray-700 outline-none disabled:border-transparent disabled:text-gray-500;
  /* border: 1px solid transparent; */
}
.dropdown-selector-item > span {
  @apply whitespace-normal;
}
.dropdown-selector-item.compact {
  @apply px-3 py-0.5;
}
.dropdown-selector-item[data-headlessui-state~="active"] {
  @apply bg-gray-200;
}
.dropdown-selector-item[data-headlessui-state~="selected"] {
  @apply bg-gray-300;
}
.dropdown-selector-item-duplicated[data-headlessui-state~="active"] {
  @apply text-gray-500;
}

/*
  DropdownSelector Item Color
*/
.dropdown-selector-item-purple[data-headlessui-state~="active"] {
  @apply bg-purple-50;
}
.dropdown-selector-item-purple[data-headlessui-state~="selected"] {
  @apply bg-purple-100;
}
.dropdown-selector-item-red[data-headlessui-state~="active"] {
  @apply bg-red-50;
}
.dropdown-selector-item-red[data-headlessui-state~="selected"] {
  @apply bg-red-100;
}
.dropdown-selector-item-yellow[data-headlessui-state~="active"] {
  @apply bg-yellow-50;
}
.dropdown-selector-item-yellow[data-headlessui-state~="selected"] {
  @apply bg-yellow-100;
}
.dropdown-selector-item-green[data-headlessui-state~="active"] {
  @apply bg-green-50;
}
.dropdown-selector-item-green[data-headlessui-state~="selected"] {
  @apply bg-green-100;
}
.dropdown-selector-item-blue[data-headlessui-state~="active"] {
  @apply bg-blue-50;
}
.dropdown-selector-item-blue[data-headlessui-state~="selected"] {
  @apply bg-blue-100;
}
.dropdown-selector-item-gray[data-headlessui-state~="active"] {
  @apply bg-gray-100;
}
.dropdown-selector-item-gray[data-headlessui-state~="selected"] {
  @apply bg-gray-200;
}
.dropdown-selector-item-orange[data-headlessui-state~="active"] {
  @apply bg-orange-50;
}
.dropdown-selector-item-orange[data-headlessui-state~="selected"] {
  @apply bg-orange-100;
}
/*
  DropdownSelector Button
*/
.dropdown-button {
  @apply w-full cursor-pointer border border-solid border-transparent bg-inherit text-left outline-none disabled:text-gray-500;
}

/*
  DropdownSelector Button Color
*/
.dropdown-button-purple:hover:not([disabled]) {
  @apply border-purple-500;
}
.dropdown-button-purple:active:not([disabled]) {
  @apply border-purple-50 bg-purple-50;
}

/*
  Dropdown Panel
*/
.dropdown div.expand-container {
  @apply hidden;
}
.dropdown div.expand-container.expanded {
  @apply block;
}
.dropdown-animated div.expand-container {
  @apply block overflow-y-auto;
}
.dropdown-animated div.expand-container.expanded {
  @apply delay-300;
}
.dropdown-animated div.expand-target {
  @apply h-0 -translate-y-1/4 translate-x-0 opacity-0 transition-all duration-300;
  margin-top: -100%;
}
.dropdown-animated div.expand-target.expanded {
  @apply mt-0 h-full translate-x-0 translate-y-0 opacity-100;
}
