button.giboo-primary-btn {
  @apply flex h-10 items-center justify-center rounded border-none bg-purple-500 px-3 py-1 text-base  font-normal not-italic leading-5 text-white disabled:border disabled:border-solid disabled:border-gray-500 disabled:bg-gray-200 disabled:text-gray-500;
}
button.giboo-primary-btn:hover:not([disabled]) {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}
button.giboo-primary-btn:focus:not([disabled]),
button.giboo-primary-btn:active:not([disabled]) {
  @apply shadow-sm;
}
.giboo-primary-btn-label {
  @apply px-2;
}
button.giboo-primary-btn-purple {
  @apply bg-purple-500;
}
button.giboo-primary-btn-red {
  @apply bg-red-500;
}
button.giboo-primary-btn-yellow {
  @apply bg-yellow-500;
}
button.giboo-primary-btn-green {
  @apply bg-green-500;
}
button.giboo-primary-btn-blue {
  @apply bg-blue-500;
}
button.giboo-primary-btn-gray {
  @apply bg-gray-500;
}
button.giboo-secondary-btn {
  @apply flex h-10 items-center justify-center rounded border border-solid border-purple-500 bg-white px-3 py-1 text-base font-normal not-italic leading-5 text-purple-500 disabled:border  disabled:border-gray-50 disabled:bg-gray-200  disabled:text-gray-500;
}
button.giboo-secondary-btn:hover:not([disabled]) {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}
button.giboo-secondary-btn:focus:not([disabled]),
button.giboo-secondary-btn:active:not([disabled]) {
  @apply shadow-sm;
}
.giboo-secondary-btn-label {
  @apply px-2;
}
button.giboo-secondary-btn.tonal:not([disabled]) {
  @apply text-gray-800;
}
button.giboo-secondary-btn.tonal:not([hover]),
button.giboo-secondary-btn.tonal:not([focus]) {
  @apply border-gray-500;
}
button.giboo-secondary-btn.active:not([disabled]) {
  @apply font-semibold;
}
button.giboo-secondary-btn-purple,
button.giboo-secondary-btn.tonal:hover:not([disabled]),
button.giboo-secondary-btn-purple.tonal:hover:not([disabled]),
button.giboo-secondary-btn.active:not([disabled]),
button.giboo-secondary-btn-purple.active:not([disabled]) {
  @apply border-purple-500 text-purple-500;
}
button.giboo-secondary-btn-red,
button.giboo-secondary-btn-red.tonal:hover:not([disabled]),
button.giboo-secondary-btn-red.active:not([disabled]) {
  @apply border-red-500 text-red-500;
}
button.giboo-secondary-btn-yellow,
button.giboo-secondary-btn-yellow.tonal:hover:not([disabled]),
button.giboo-secondary-btn-yellow.active:not([disabled]) {
  @apply border-yellow-500 text-yellow-500;
}
button.giboo-secondary-btn-green,
button.giboo-secondary-btn-green.tonal:hover:not([disabled]),
button.giboo-secondary-btn-green.active:not([disabled]) {
  @apply border-green-500 text-green-500;
}
button.giboo-secondary-btn-blue,
button.giboo-secondary-btn-blue.tonal:hover:not([disabled]),
button.giboo-secondary-btn-blue.active:not([disabled]) {
  @apply border-blue-500 text-blue-500;
}
button.giboo-secondary-btn-gray,
button.giboo-secondary-btn-gray.tonal:hover:not([disabled]),
button.giboo-secondary-btn-gray.active:not([disabled]) {
  @apply border-gray-200 bg-gray-100 text-gray-800;
}
button.giboo-plain-btn {
  @apply flex items-center  justify-center rounded border border-solid border-gray-500 px-3 py-1.5 text-base font-normal not-italic leading-5 text-gray-700 disabled:border-gray-500 disabled:text-gray-500;
  background: transparent;
}
button.giboo-plain-btn.no-outline {
  @apply border-0;
}
button.giboo-plain-btn.underline > .giboo-plain-btn-label {
  @apply border-0;
}
button.giboo-plain-btn:hover:not([disabled]) {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

.giboo-plain-btn-label {
  @apply px-1;
}
button.giboo-plain-btn-purple {
  @apply border-purple-500 text-purple-500;
}
button.giboo-plain-btn-red {
  @apply border-red-500 text-red-500;
}
button.giboo-plain-btn-yellow {
  @apply border-yellow-500 text-yellow-500;
}
button.giboo-plain-btn-green {
  @apply border-green-500 text-green-500;
}
button.giboo-plain-btn-blue {
  @apply border-blue-500 text-blue-500;
}
.giboo-project-template-button {
  @apply m-3 h-40  w-52 rounded-lg border-none bg-purple-50 hover:border hover:border-solid hover:border-purple-500;
}
.giboo-project-template-button-selected {
  @apply m-3 h-40  w-52 rounded-lg border-none bg-purple-500;
}
.giboo-project-template-button-label {
  @apply text-base font-normal not-italic leading-5 text-purple-500;
  /* CSK - #5C38A7/500 */
}
.giboo-project-template-button-label-selected {
  @apply text-base font-normal not-italic leading-5 text-white;
}
