@font-face {
  font-family: 'giboo-icon';
  src:  url('fonts/giboo-icon.eot?tlom44');
  src:  url('fonts/giboo-icon.eot?tlom44#iefix') format('embedded-opentype'),
    url('fonts/giboo-icon.ttf?tlom44') format('truetype'),
    url('fonts/giboo-icon.woff?tlom44') format('woff'),
    url('fonts/giboo-icon.svg?tlom44#giboo-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gi-"], [class*=" gi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'giboo-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gi-assistant:before {
  content: "\e982";
}
.gi-sidekick:before {
  content: "\e983";
}
.gi-sidekick2:before {
  content: "\e984";
}
.gi-trash:before {
  content: "\e981";
  color: #5f5f5f;
}
.gi-pencil-edit:before {
  content: "\e97e";
}
.gi-pencil1:before {
  content: "\e97f";
  color: #5f5f5f;
}
.gi-view-document:before {
  content: "\e980";
}
.gi-lightbulb:before {
  content: "\e97c";
}
.gi-chat-plus:before {
  content: "\e97d";
}
.gi-thumbs-up:before {
  content: "\e97a";
}
.gi-thumbs-down:before {
  content: "\e97b";
}
.gi-estimated:before {
  content: "\e978";
}
.gi-predicted:before {
  content: "\e979";
}
.gi-arrow-down-right:before {
  content: "\e977";
}
.gi-wand:before {
  content: "\e975";
}
.gi-pencil_edit:before {
  content: "\e976";
}
.gi-project:before {
  content: "\e974";
}
.gi-redo:before {
  content: "\e973";
}
.gi-add-ring:before {
  content: "\e902";
}
.gi-copy:before {
  content: "\e972";
}
.gi-funding_intermediary:before {
  content: "\e966";
}
.gi-community:before {
  content: "\e967";
}
.gi-daf:before {
  content: "\e968";
}
.gi-federated_giving:before {
  content: "\e969";
}
.gi-field_builder:before {
  content: "\e96a";
}
.gi-hybrid:before {
  content: "\e96b";
}
.gi-private_operating:before {
  content: "\e96c";
}
.gi-private:before {
  content: "\e96d";
}
.gi-service_provider:before {
  content: "\e96e";
}
.gi-community1:before {
  content: "\e96f";
}
.gi-private-operating:before {
  content: "\e970";
}
.gi-private1:before {
  content: "\e971";
}
.gi-public:before {
  content: "\e91d";
}
.gi-local:before {
  content: "\e91b";
}
.gi-right-arrow:before {
  content: "\e965";
}
.gi-done:before {
  content: "\e963";
}
.gi-progress:before {
  content: "\e964";
}
.gi-start:before {
  content: "\e962";
}
.gi-time:before {
  content: "\e961";
}
.gi-open:before {
  content: "\e960";
}
.gi-calendar:before {
  content: "\e95e";
}
.gi-stage:before {
  content: "\e95f";
}
.gi-pencil:before {
  content: "\e945";
}
.gi-corporate:before {
  content: "\e91c";
}
.gi-federal:before {
  content: "\e91e";
}
.gi-state:before {
  content: "\e923";
}
.gi-letter-endofyear:before {
  content: "\e924";
}
.gi-letter-follow:before {
  content: "\e925";
}
.gi-letter-loi:before {
  content: "\e926";
}
.gi-letter-progress:before {
  content: "\e95b";
}
.gi-letter-renewal:before {
  content: "\e95c";
}
.gi-letter-thankyou:before {
  content: "\e95d";
}
.gi-send:before {
  content: "\e90f";
}
.gi-chat:before {
  content: "\e910";
}
.gi-government:before {
  content: "\e919";
}
.gi-stars:before {
  content: "\e91a";
}
.gi-bookmark-saved:before {
  content: "\e909";
}
.gi-bookmark-unsaved:before {
  content: "\e90a";
}
.gi-funder:before {
  content: "\e90b";
}
.gi-grant:before {
  content: "\e90d";
}
.gi-star-filled:before {
  content: "\e90e";
}
.gi-exclamation-mark:before {
  content: "\e900";
}
.gi-add-ring-min:before {
  content: "\e901";
}
.gi-add:before {
  content: "\e903";
}
.gi-angle-down:before {
  content: "\e904";
}
.gi-angle-left:before {
  content: "\e905";
}
.gi-angle-right:before {
  content: "\e906";
}
.gi-angle-up:before {
  content: "\e907";
}
.gi-archive-banned:before {
  content: "\e908";
}
.gi-archive-edit:before {
  content: "\e90c";
}
.gi-archive:before {
  content: "\e911";
}
.gi-calculator:before {
  content: "\e912";
}
.gi-chart:before {
  content: "\e913";
}
.gi-check:before {
  content: "\e914";
}
.gi-circle-check:before {
  content: "\e915";
}
.gi-circle:before {
  content: "\e916";
}
.gi-cloud:before {
  content: "\e917";
}
.gi-crypto:before {
  content: "\e918";
}
.gi-date-range:before {
  content: "\e91f";
}
.gi-dollar:before {
  content: "\e920";
}
.gi-done-ring:before {
  content: "\e921";
}
.gi-donor:before {
  content: "\e922";
}
.gi-download:before {
  content: "\e927";
}
.gi-expand-down:before {
  content: "\e928";
}
.gi-expand-left:before {
  content: "\e929";
}
.gi-expand-right:before {
  content: "\e92a";
}
.gi-expand-up:before {
  content: "\e92b";
}
.gi-find-new-funder:before {
  content: "\e92c";
}
.gi-flash:before {
  content: "\e92d";
}
.gi-gift:before {
  content: "\e92e";
}
.gi-globe:before {
  content: "\e92f";
}
.gi-group:before {
  content: "\e930";
}
.gi-hand-coin-outline:before {
  content: "\e931";
}
.gi-heart-add:before {
  content: "\e932";
}
.gi-heart-full:before {
  content: "\e933";
}
.gi-heart:before {
  content: "\e934";
}
.gi-home:before {
  content: "\e935";
}
.gi-info:before {
  content: "\e936";
}
.gi-label:before {
  content: "\e937";
}
.gi-left-arrow:before {
  content: "\e938";
}
.gi-list-icon-full:before {
  content: "\e939";
}
.gi-list-icon:before {
  content: "\e93a";
}
.gi-location-dot:before {
  content: "\e93b";
}
.gi-lock:before {
  content: "\e93c";
}
.gi-mail:before {
  content: "\e93d";
}
.gi-map:before {
  content: "\e93e";
}
.gi-meatballs-menu:before {
  content: "\e93f";
}
.gi-menu:before {
  content: "\e940";
}
.gi-notes:before {
  content: "\e941";
}
.gi-notification:before {
  content: "\e942";
}
.gi-off:before {
  content: "\e943";
}
.gi-on:before {
  content: "\e944";
}
.gi-phone:before {
  content: "\e946";
}
.gi-pie-chart:before {
  content: "\e947";
}
.gi-puzzle:before {
  content: "\e948";
}
.gi-refresh-dot:before {
  content: "\e949";
}
.gi-refresh:before {
  content: "\e94a";
}
.gi-refund-back:before {
  content: "\e94b";
}
.gi-search-user:before {
  content: "\e94c";
}
.gi-search:before {
  content: "\e94d";
}
.gi-setting:before {
  content: "\e94e";
}
.gi-shield:before {
  content: "\e94f";
}
.gi-sigin-in-circle:before {
  content: "\e950";
}
.gi-sign-out-circle:before {
  content: "\e951";
}
.gi-sort:before {
  content: "\e952";
}
.gi-star:before {
  content: "\e953";
}
.gi-tumer:before {
  content: "\e954";
}
.gi-upload:before {
  content: "\e955";
}
.gi-user:before {
  content: "\e956";
}
.gi-view-hide:before {
  content: "\e957";
}
.gi-view:before {
  content: "\e958";
}
.gi-waterfall:before {
  content: "\e959";
}
.gi-x:before {
  content: "\e95a";
}
