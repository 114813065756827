.chat-main-container {
  @apply rounded-b-none  border-gray-300;
}
.scrollbar-container {
  @apply h-full w-full rounded-md border-none bg-gray-50  bg-opacity-10 bg-clip-padding px-4 backdrop-blur-sm backdrop-filter;
}
.chat-message-list {
  @apply bg-gray-50;
  max-height: calc(100vh - 300px);
}
.cs-message:not(:only-child) {
  margin: 10px 0 0 0;
}
.cs-message.cs-message--incoming.chat-message-box .cs-message__content {
  @apply giboo-gradient-bg rounded text-white;
}
.cs-message.cs-message--outgoing.chat-message-box .cs-message__content {
  @apply rounded bg-gray-200;
}
.cs-message-list .cs-typing-indicator {
  @apply bg-gray-50 px-4;
  .cs-typing-indicator__text {
    @apply font-poppins text-xs text-purple-500;
  }
  .cs-typing-indicator__dot {
    @apply bg-purple-500;
    box-sizing: inherit;
    border-radius: 100%;
    display: inline-block;
    height: 4px;
    width: 4px;
  }
}
