@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.min.css";
@import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
@import "chat-override-style.scss";
@import "react-advanced-cropper/dist/style.css";
@import "intro.js/introjs.css";
@import "intro-js-override-style.scss";

:root {
  @apply overscroll-none; // remove's the bounce behaviour on scroll end.
  overflow-y: scroll;
  --rs-text-primary: #1f1f1f;
  --rs-tooltip-bg: #ffffff;
  --rs-slider-progress: #5c38a7;
  --rs-slider-thumb-border: #5c38a7;
  --rs-slider-thumb-hover-shadow: #5c38a7;
}
body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ql-normal > .ql-container > .ql-editor > * {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
#content {
  @apply p-0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.giboo-body {
  @apply h-full min-h-screen overflow-hidden pt-20;
}
.giboo-footer {
  padding: 40px;
}
.h0 {
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 78px;
}
h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

p,
pre {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
b {
  font-weight: 500;
}
p.semibold,
h5.semibold,
h6.semibold {
  @apply font-semibold;
}
.subtitle {
  @apply text-base font-normal not-italic leading-5 text-gray-500;
}
.box-title {
  @apply text-base font-normal text-black;
}
.box-subtitle {
  @apply text-sm font-normal text-gray-700;
}
.box-normal {
  @apply text-base font-normal not-italic leading-5 text-black;
}
div.giboo-hr {
  @apply w-full border border-solid border-gray-600;
  border: 0.5px;
}
a,
a:link,
a:visited {
  @apply font-semibold not-italic text-purple-500 no-underline;
}
a:hover {
  @apply font-semibold not-italic text-purple-600 no-underline;
}
a:active {
  @apply font-semibold not-italic text-purple-700 no-underline;
}
.live-search-item {
  @apply order-none flex h-16  flex-none grow-0 flex-col items-center justify-center rounded bg-white hover:cursor-pointer hover:bg-purple-50;
  /* Auto layout */
  /* CSK - #9B9B9B/0 */
  /* Inside auto layout */
}
.live-search-npo-name {
  @apply order-none h-8 flex-none grow-0 self-stretch px-7 pt-2 text-base font-semibold not-italic leading-5 text-purple-900;
  /* CSK - #5C38A7/900 */
  /* Inside auto layout */
}
.live-search-npo-address {
  @apply order-1 h-8 flex-none grow-0 self-stretch px-7 pt-1 text-sm font-normal not-italic leading-4 text-purple-900;
  /* identical to box height */
  /* CSK - #5C38A7/900 */
  /* Inside auto layout */
}

.truncate {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

label.form-label {
  @apply order-none h-5 flex-none grow-0 pt-5 text-base font-normal not-italic leading-5 text-gray-900;
  /* P */
  /* CSK - #9B9B9B/900 */
  /* Inside auto layout */
}

.donor-item {
  @apply mt-2.5 h-36 rounded-lg bg-white;
}
.donor-item-name {
  @apply mt-5 pl-8 text-lg font-semibold not-italic leading-6 text-black;
}
.donor-item-score {
  @apply mt-5 text-lg not-italic leading-6 text-black;
}
.donor-item-address {
  @apply mt-3 pl-5 pr-2.5 text-sm font-normal not-italic leading-4 text-purple-900;
}
.donor-item-website {
  @apply mt-3 pl-5 pr-2.5 text-sm font-normal not-italic leading-4 text-purple-900;
}
.donor-item-glyphicon {
  @apply pr-2.5 text-purple-500;
}
.error-message {
  @apply h-0 py-1 text-lg font-semibold not-italic leading-6 text-red-800;
}
.purple {
  @apply text-purple-500;
}
.light-purple {
  @apply text-purple-100;
}
.red {
  @apply text-red-500;
}
.light-red {
  @apply text-red-100;
}
.yellow {
  @apply text-yellow-500;
}
.light-yellow {
  @apply text-yellow-100;
}
.green {
  @apply text-green-500;
}
.light-green {
  @apply text-green-100;
}
.blue {
  @apply text-blue-500;
}
.light-blue {
  @apply text-blue-100;
}
.gray {
  @apply text-gray-500;
}
.light-gray {
  @apply text-gray-100;
}
.gray-600 {
  @apply text-gray-600;
}
.gray-700 {
  @apply text-gray-700;
}
.gray-800 {
  @apply text-gray-800;
}
.gray-900 {
  @apply text-gray-900;
}
.giboo-modal-lg {
  --bs-modal-width: 911px;
}
.loading-light-shimmer div.loading-light-shimmer-child div,
.loading-light-shimmer span.loading-light-shimmer-child span {
  transition: all 0.3s;
}
.loading-light-shimmer-on div.loading-light-shimmer-child div,
.loading-light-shimmer-on span.loading-light-shimmer-child span {
  min-width: 24px;
  min-height: 24px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  color: transparent;
  background: #f4f4f4;
  background-image: linear-gradient(to right, #f4f4f4 0%, #ececec 20%, #f4f4f4 40%, #f4f4f4 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  border-radius: 8px;
  border-color: transparent;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.giboo-popover {
  @apply absolute z-40 float-left;
}
.prevent-select {
  @apply select-none;
}
.giboo-fixed-left-bottom {
  @apply fixed bottom-0 left-0;
}

.giboo-modal-fullscreen {
  @apply m-0 h-full w-screen max-w-none;
}
.max-2-lines {
  @apply overflow-hidden text-ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fade-out {
  @apply opacity-0 transition-all duration-500 ease-linear;
}

.form-control {
  @apply flex w-full appearance-none border-gray-300  px-3 py-2 font-poppins leading-tight text-gray-700 focus:border-gray-500 focus:ring-0 focus:ring-gray-900;
  height: 48px;
  border-radius: 4px;
  border-width: 1px;
}
.form-invalid {
  @apply border-purple-500 bg-purple-50 text-gray-700;
}
.star-ul {
  list-style-image: url("components/css/star_bullet.svg");
}

// recharts
.recharts-responsive-container {
  .recharts-cartesian-axis-ticks {
    .recharts-cartesian-axis-tick {
      // .recharts-cartesian-axis-tick-line[orientation="bottom"] {
      //   display: none;
      // }
      .recharts-cartesian-axis-tick-line[orientation="left"] {
        display: none;
      }
    }
  }
}
// svg > g.recharts-layer.recharts-bar > g.recharts-layer.recharts-label-list > text {
//   tspan {
//     @apply fill-gray-500;
//   }
// }
// text editor
.ql-header {
  .ql-snow .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  .ql-snow .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2;
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: #444;
  }
}
.react-quill-readonly {
  .ql-container {
    border: 0 !important ;
    p {
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}
.react-quill-readonly-preview {
  .ql-container {
    border: 0 !important ;
    p {
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #1f1f1f !important;
    }
  }
}
.ql-container {
  border: 0 !important ;
  p {
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1f1f1f !important;
  }
  overflow: visible;
}
.ql-editor::before {
  color: #9b9b9b !important; /* Change the color to white */
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: #9b9b9b;
  font-family: "poppins";
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.word-cloud__word {
  font-family: sans-serif;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.word-cloud__word:hover {
  opacity: 1;
}

.word-cloud__word:not(:hover) {
  opacity: 0.6;
}

ul.intro-icon-1 {
  list-style-image: url("./components/img/intro-icon-1.svg");
}

ul.intro-icon-2 {
  list-style-image: url("./components/img/intro-icon-2.svg");
}

ul.intro-icon-3 {
  list-style-image: url("./components/img/intro-icon-3.svg");
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  row-gap: 8px;
  column-gap: 4px;
}

.remaining-tags {
  display: flex;
  align-items: center;
}
.Toastify__toast {
  margin-bottom: 0px;
  min-width: 424px;
  left: 8px;
}
.giboo-gradient-bg {
  // @apply bg-gradient-to-br from-purple-demo  to-blue-800;

  background: linear-gradient(
    140deg,
    #e43f83 0%,
    #a62999 13.26%,
    #6716b0 23.82%,
    #4211a1 36.33%,
    #350f9c 47.54%,
    #3f37c0 59.92%,
    #4960e6 72.82%,
    #5c94e8 85.19%,
    #70c5ec 100%
  );
  background-repeat: repeat;
}
.giboo-gradient-border {
  border: 3px solid transparent;
  border-image: linear-gradient(
    140deg,
    #e43f83 0%,
    #a62999 13.26%,
    #6716b0 23.82%,
    #4211a1 36.33%,
    #350f9c 47.54%,
    #3f37c0 59.92%,
    #4960e6 72.82%,
    #5c94e8 85.19%,
    #70c5ec 100%
  );
  border-image-slice: 1;
  width: 100%;
  height: 100%;
}
// .giboo-gradient-bg-hover:hover {
//   animation-duration: 3s;
//   animation-fill-mode: forwards;
//   animation-iteration-count: infinite;
//   animation-name: gibooGradient;
//   animation-timing-function: linear;
// }

@keyframes gibooGradient {
  0% {
    background-position: -468px -400px;
  }
  100% {
    background-position: 468px 400px;
  }
}
//RS MODAL
.rs-modal-wrapper {
  overscroll-behavior: contain;
}
//RS TABLE
.rs-table {
  .rs-table-cell {
    .rs-table-cell-content {
      padding: 8px 10px;
    }
  }
}
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background-color: #f4f4f4 !important;
}
.rs-table-scrollbar-handle {
  background-color: #dedede !important;
}
.rs-table-scrollbar {
  background-color: #f4f4f4 !important;
}

//table-cell-date-picker
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000000 !important;
  text-align: center !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #e5e5ea;
  transition: none !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
  transition: none !important;
}
.rs-picker-default .rs-picker-toggle:not(:hover) {
  border: none;
}
.rsuite-date-picker {
  .rs-picker-default .rs-picker-toggle:hover {
    border: none !important;
  }
}
.table-cell-date-picker {
  .rs-picker-toggle-placeholder,
  .rs-picker-toggle-value {
    font-size: 12px !important;
  }
  .rs-picker-toggle {
    background-color: inherit !important;
    font-size: 12px !important;
  }
  .rs-picker-toggle:hover {
    border-color: #bfaee4 !important;
    border-width: 1px !important;
    transition: none !important;
  }
}
.rs-picker-date-menu {
  z-index: 9999 !important;
}
.rs-picker-toggle-caret.rs-icon {
  fill: #9578d2;
}
.rs-btn-primary {
  background-color: #3498ff !important;
}
.rs-btn:focus,
.rs-btn:hover {
  border-color: #e5e5ea !important;
  text-decoration: none;
}
.rs-toggle-checked .rs-toggle-presentation {
  background-color: #5c38a7;
}
.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #5c38a7;
}
.rs-pagination .rs-pagination-btn {
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: #fff;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rs-pagination-btn.rs-pagination-btn-active {
  border: none;
  background-color: #5c38a7;
  color: white;
}
.rs-pagination-btn.rs-pagination-btn-active:focus,
.rs-pagination-btn.rs-pagination-btn-active:hover {
  background-color: #5c38a7;
  color: white;
  border: none;
  box-shadow: none !important;
}
.__floater[x-placement="left-start"] svg,
.__floater[x-placement="left"] svg {
  display: block;
  transform: translate(-5px);
}
.__floater[x-placement="right-start"] svg,
.__floater[x-placement="right"] svg {
  display: block;
  transform: translate(5px);
}
// .full-screen-container {
//   // position: fixed;
//   // top: 58px;
//   // left: 0;
//   // width: calc(100vw - 58px);
//   // height: calc(100vh - 58px);
//   // overflow-y: scroll;
//   // &.nav-collapse {
//   //   width: calc(100vw);
//   // }
// }
.mentions {
  @apply mb-3;
}
.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
}
.mentions--singleLine .mentions__input {
  @apply rounded border border-gray-300 outline-none  focus:ring-0 focus:ring-gray-900;
  padding: 9px;
  background: none;
}

.mentions--multiLine .mentions__control {
  @apply border-none bg-none text-sm font-normal;
}
.mentions--multiLine .mentions__highlighter {
  @apply text-sm font-semibold;
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  @apply rounded border border-gray-300 text-sm outline-none  focus:ring-0 focus:ring-gray-900;
  padding: 9px;
  min-height: 63px;
}

.mentions__suggestions__list {
  @apply rounded border border-gray-300 bg-white;
}
.mentions__suggestions__item {
  @apply bg-white;
  padding: 5px 15px;
}
.mentions__suggestions__item--focused {
  @apply bg-purple-50 text-sm  text-purple-500;
}
.mentions__mention {
  @apply text-sm  text-purple-500;
}

#project-management-tooltip.rs-popover {
  &.placement-top > .rs-popover-arrow:after {
    border-top-color: #5c38a7 !important;
  }
  &.placement-top-start > .rs-popover-arrow:after {
    border-top-color: #5c38a7 !important;
  }
  &.placement-top-end > .rs-popover-arrow:after {
    border-top-color: #5c38a7 !important;
  }
  &.placement-bottom > .rs-popover-arrow:after {
    border-bottom-color: #5c38a7 !important;
  }
  &.placement-bottom-start > .rs-popover-arrow:after {
    border-bottom-color: #5c38a7 !important;
  }
  &.placement-bottom-end > .rs-popover-arrow:after {
    border-bottom-color: #5c38a7 !important;
  }
  &.placement-left > .rs-popover-arrow:after {
    border-left-color: #5c38a7 !important;
  }
  &.placement-left-start > .rs-popover-arrow:after {
    border-left-color: #5c38a7 !important;
  }
  &.placement-left-end > .rs-popover-arrow:after {
    border-left-color: #5c38a7 !important;
  }
  &.placement-right > .rs-popover-arrow:after {
    border-right-color: #5c38a7 !important;
  }
  &.placement-right-start > .rs-popover-arrow:after {
    border-right-color: #5c38a7 !important;
  }
  &.placement-right-end > .rs-popover-arrow:after {
    border-right-color: #5c38a7 !important;
  }
}
#hover-tooltip.rs-popover {
  &.placement-top > .rs-popover-arrow:after {
    border-top-color: #727272 !important;
  }
  &.placement-top-start > .rs-popover-arrow:after {
    border-top-color: #727272 !important;
  }
  &.placement-top-end > .rs-popover-arrow:after {
    border-top-color: #727272 !important;
  }
  &.placement-bottom > .rs-popover-arrow:after {
    border-bottom-color: #727272 !important;
  }
  &.placement-bottom-start > .rs-popover-arrow:after {
    border-bottom-color: #727272 !important;
  }
  &.placement-bottom-end > .rs-popover-arrow:after {
    border-bottom-color: #727272 !important;
  }
  &.placement-left > .rs-popover-arrow:after {
    border-left-color: #727272 !important;
  }
  &.placement-left-start > .rs-popover-arrow:after {
    border-left-color: #727272 !important;
  }
  &.placement-left-end > .rs-popover-arrow:after {
    border-left-color: #727272 !important;
  }
  &.placement-right > .rs-popover-arrow:after {
    border-right-color: #727272 !important;
  }
  &.placement-right-start > .rs-popover-arrow:after {
    border-right-color: #727272 !important;
  }
  &.placement-right-end > .rs-popover-arrow:after {
    border-right-color: #727272 !important;
  }
}
//input
input[type="range"] {
  background-color: #5c38a7 !important;
  border-color: #ffffff;
}

input[type="range"]::-moz-range-track {
  background-color: #5c38a7 !important;
}

input[type="range"]::-moz-range-thumb {
  background-color: #ffffff;
}
.rc-table {
  table {
    border-collapse: collapse;
    tr {
      &:hover {
        td {
          color: #5f5f5f;
        }
      }
    }
    th {
      color: #5f5f5f;
      font-weight: 400;
      border-top: 1px solid #dedede;
      padding: 0px;
      height: 36px;
    }
    td,
    th {
      color: #5f5f5f;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      padding: 0px;
      height: 36px;
    }
    th {
      text-align: left;
      padding-left: 16px;
      font-size: 12px;
      font-style: poppins;
    }
    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}
.rc-table-project-table-list {
  .rc-table-content {
    @apply rounded border border-none border-gray-300 bg-transparent p-0;
    .rc-table-thead {
      border-top: 0px;
      .rc-table-cell {
        @apply text-sm font-semibold text-gray-900;
      }
    }
    // tbody > tr:last-child {
    //   border-bottom: 0px;
    // }
  }
  .rc-table .rc-table-cell {
    @apply cursor-default bg-gray-50;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-top: 16px;
    padding-left: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
  }
}
.rs-steps {
  .rs-steps-item-icon-wrapper .rs-steps-item-icon {
    @apply grid h-4 w-4 place-items-center;
    // margin-top: 36px;
    margin-left: 8px;
  }
  .rs-steps-vertical .rs-steps-item-tail {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .rs-steps-item-description {
    margin-top: -28px !important;
  }
  .rs-steps-item-status-finish .rs-steps-item-tail,
  .rs-steps-item-status-finish .rs-steps-item-title:after {
    border-color: #5c38a7 !important;
  }
  .rs-steps-item-status-process .rs-steps-item-tail,
  .rs-steps-item-status-process .rs-steps-item-title:after,
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    border-color: #8e8e93 !important;
  }
}
.table-date-input {
  .rs-picker-toggle-placeholder {
    @apply text-xs;
    padding-top: 2px;
  }
  .rs-picker-toggle-value {
    @apply text-xs;
    padding-top: 2px;
  }
}

.giboo-why-matched-hover:hover {
  background: var(
    --Linear,
    linear-gradient(116deg, rgba(239, 239, 239, 0.6) 10.92%, rgba(255, 255, 255, 0.08) 96.4%)
  );
}

.giboo-why-matched {
  background: var(
    --Linear,
    linear-gradient(116deg, rgba(239, 239, 239, 0.6) 10.92%, rgba(255, 255, 255, 0.08) 96.4%)
  );
}

.giboo-anim-loading-dot {
  fill: #9b9b9b;
  animation: moveUpDown 1.5s infinite cubic-bezier(0.6, 0, 0.2, 1);
}
.giboo-anim-loading-dot-container {
  :nth-child(2) {
    animation-delay: 0.1s;
  }
  :nth-child(3) {
    animation-delay: 0.2s;
  }
}

@keyframes moveUpDown {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-3px);
  }
}

.giboo-chat-component {
  p {
    @apply text-sm;
    margin-top: 0px !important;
  }
}

.giboo-purple-gradient {
  background: linear-gradient(93deg, #6c47ba 7.92%, #492e84 100%);
}

//table cell individual hover
.rc-table-row {
  .rc-table-cell {
    &:hover {
      @apply bg-purple-50;
    }
  }
}
.rs-drawer-wrapper {
  z-index: 8888 !important;
}

.giboo-btn-text-purple {
  @apply cursor-pointer hover:font-semibold hover:text-purple-500 active:text-purple-700;
}
.giboo-btn-text-gray {
  @apply cursor-pointer hover:font-semibold hover:text-gray-600 active:text-gray-700;
}
.giboo-btn-icon {
  @apply cursor-pointer p-2 hover:rounded-full hover:bg-purple-100  active:bg-purple-500;
}
.rs-slider-graduator > ol > li:before,
.rs-slider-graduator > ol > li:last-child:after {
  display: none;
}

.blur-text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2.4px);
}

.org-profile-bg {
  background: linear-gradient(255.4deg, #355be0 0%, #9430d1 100%);
  opacity: 0.7;
  backdrop-filter: blur(2px);
}
.org-claim-info-bg {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: linear-gradient(
    116deg,
    rgba(239, 239, 239, 0.6) 17.69%,
    rgba(255, 255, 255, 0.08) 43.36%,
    rgba(255, 255, 255, 0.08) 79.3%,
    rgba(239, 239, 239, 0.6) 96.4%
  );
}

.npo-org-message-bg {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: linear-gradient(116deg, #efefef 17.69%, #fff 43.36%, #fff 79.3%, #efefef 96.4%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.content-hide-wrapper-bg {
  background: linear-gradient(
    116deg,
    rgba(239, 239, 239, 0.6) 168%,
    rgba(255, 255, 255, 0.08) 253.48%
  ) !important;
}
