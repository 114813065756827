.creatable-select-input {
  input {
    @apply focus:outline-none focus:ring-0;
  }
}

.custom-select-search__placeholder::before {
  content: url("../../assets//images//search.svg");
  max-width: fit-content;
  width: 16px;
  height: 16px;
}

.creatable-select-input-async {
  input {
    @apply focus:outline-none focus:ring-0;
  }
}
.css-tj5bde-Svg {
  width: 20px;
  height: 20px;
  @apply -mr-1;
}
