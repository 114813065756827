nav,
.giboo-body-with-nav-left {
  --nav-left-width: 94px;
  --nav-left-width-expanded: 250px;
}
.logo-label {
  @apply mx-5 items-center text-center text-2xl font-bold leading-8 text-purple-500;
}
.giboo-nav-container {
  @apply fixed top-0 z-40 w-screen bg-white shadow-sm;
  height: 58px;
}
.giboo-nav {
  @apply mx-auto my-0 h-full  px-6 py-0;
  width: 1200px;
}
.giboo-nav-left-logo {
  min-height: 80px;
}
.giboo-nav-left {
  @apply fixed left-0 z-50  h-screen gap-5 bg-white transition-all;
  width: var(--nav-left-width-expanded);
  min-width: var(--nav-left-width-expanded);
  transition-duration: 0.4s;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.giboo-nav-left-item-container {
  @apply gap-2.5 px-2 py-0;
}
.giboo-nav-left-item {
  @apply mx-auto my-0 h-10 w-full gap-4 rounded bg-transparent px-3 py-2 transition-all;
  transition-duration: 0.4s;
}
.giboo-nav-left-item svg,
.giboo-nav-left-item i {
  margin: 0 auto;
  min-width: 24px;
}
.giboo-nav-left-item path,
.giboo-nav-left-item i {
  @apply text-gray-700;
}
.giboo-nav-left-item.active path,
.giboo-nav-left-item.active i {
  @apply text-gray-900;
}
.giboo-nav-left-item.active {
  @apply bg-purple-50;
}
.giboo-nav-left-item:hover,
.giboo-nav-left-item.active:hover {
  @apply bg-purple-50;
}
.giboo-nav-left .giboo-nav-label {
  @apply h-5 w-40 overflow-hidden whitespace-nowrap font-normal text-gray-900 opacity-100 transition-all;
  transition-duration: 0.4s;
}
.giboo-nav-left-item.active .giboo-nav-label {
  @apply font-semibold;
}
.giboo-nav-left-item .tooltip {
  @apply hidden;
}
.giboo-nav-profile {
  @apply mx-auto my-0  w-full cursor-pointer gap-5 transition-all;
  transition-duration: 0.4s;
}

.giboo-body-gray {
  @apply bg-gray-50;
}
.giboo-body-with-nav-left {
  @apply bg-gray-50;
  padding-left: var(--nav-left-width-expanded);
  transition: padding-left 0.4s;
}
@media screen and (max-width: 1199.98px) {
  .giboo-nav {
    @apply w-full;
  }
  .giboo-nav-left {
    width: var(--nav-left-width);
    min-width: var(--nav-left-width);
    transition: all 0.4s;
  }
  .giboo-nav-left-item {
    @apply gap-0;
  }
  .giboo-nav-left .giboo-nav-profile {
    @apply gap-0;
  }
  .giboo-nav-left .giboo-nav-label {
    @apply w-0 opacity-0;
  }
  .giboo-nav-left-item.active {
    @apply bg-transparent;
  }
  .giboo-nav-left-item .tooltip {
    @apply block;
  }
  .giboo-body-with-nav-left {
    padding-left: var(--nav-left-width);
  }
}
